@import './variables';

// Buttons
// ------------------------------
button, a, .mat-button {

    font-weight: 400;
    outline: none;
    &:focus {
        outline: none;
    }

    &:disabled {
        opacity: 0.7;
    }

    &.mat-btn-link {
        color: $link-color-base;
        line-height: 26px;
        padding: 0;
    }

    &.mat-button-sm {

        font-weight: 400;
        line-height: 26px;
        padding: 2px 8px !important;

        i {
            font-size: 0.9rem;
        }

    }

    &.mat-button-xs {

        font-weight: 400;
        line-height: 26px;
        padding: 0 !important;
        min-width: 60px;

        i.material-icons {
            font-size: 0.85rem;
        }

    }

    &.mat-button-text {

        font-size: 0.85rem;

        i.material-icons {
            font-size: 0.85rem;
        }

    }

    .mat-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        text-decoration: none;
    }

    &.mat-primary {

        .mat-spinner svg circle {
            stroke: $link-color-base;
        }

        .mat-spinner {
            margin-left: 5px;
        }

        &.mat-button {
            color: $link-color-base;
        }

        &.mat-outline-button {
            border: 1px solid $btn-color-primary;
        }

        &:disabled {
            opacity: 0.7;
        }

    }

    &.mat-secondary {
        background-color: #f3f3f3;
    }

    &.mat-basic {

        &.mat-raised-button {

            background-color: $btn-color-secondary;

            &:hover {
                background-color: darken($btn-color-secondary, 5%);
                color: $text-color-base;
            }

            &.no-shadow {
                box-shadow: unset;
            }

        }

    }

    &.mat-select {
        border: 1px solid lighten($link-color-base, 15%);
        background-color: #fff;
        color: $link-color-base;
    }

    &.mat-primary-second {
        background-color: #4692FC;
        color: #fff;
    }

    &.mat-customize {
        background-color: #FDA638;
        color: #fff;
    }

    .btn-icon {
        font-size: 0.9rem;
    }

}

// icon buttons
.mat-icon-button {

    height: 34px !important;
    width: 34px !important;

    &.mat-primary i {
        color: $link-color-base;
    }

    &.mat-danger i {
        color: $text-color-error;
    }

}

// flat buttons
.mat-flat-button {

    font-weight: 400;

    &.mat-primary {

        background-color: $btn-color-primary;
        border: 1px solid darken($btn-color-primary, 5%);

        .mat-spinner svg circle {
            stroke: #fff;
        }

        &:disabled {
            background-color: $btn-color-primary;
            color: #fff;
            opacity: 0.7;
        }

    }

    &.mat-secondary {
        border: 1px solid #e7e7e7;
    }

    &.disabled {
        opacity: 0.8;
    }

}

.btn {

    &.btn-datepicker-addon {
        background-color: lighten($border-color-base, 12%);
        border: 1px solid $border-color-base;
        font-size: 18px;
        padding-left: 6px;
        padding-right: 6px;
    }

    &.btn-link {

        color: $link-color-base;
        font-size: $font-size-base;
        padding: 0;

        i.material-icons {
            font-size: $font-size-base;
            line-height: 1.5;
        }

        &:focus,
        &:hover,
        &:active {
            text-decoration: none !important;
            color: darken($link-color-base, 10%);
        }

    }

}

// Stroked buttons
.mat-stroked-button {

    &.mat-primary {

        border-color: $btn-color-primary;
        color: $btn-color-primary;

        &:disabled {
            color: $btn-color-primary;
            opacity: 0.3;
        }

    }

}

// Menu buttons
button, a {

    &.mat-menu-item {
        font-size: 0.9rem;
        padding: 2px 8px;
    }

}

button.menu-btn:focus {
    outline: unset !important;
}

.mat-menu-content {

    padding-bottom: 0 !important;
    padding-top: 0 !important;

    .mat-button-sm {
        font-size: 0.9rem;
        line-height: 1.5;
    }

}