.transparent {
    opacity: 0;
}

.fade {

    opacity: 0;
    transition: opacity .35s;

    &.in {
        opacity: 1;
    }

}

.flip-horizontal {
    transform: scaleX(-1);
}

.barcode-container .barcode > svg {
    width: 180px !important;
}