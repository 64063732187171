$pc-logo-dark: #30353B;
$pc-logo-tan: #ABA899;
$pc-logo-red: #C03E4F;
$pc-logo-dark-red: #A71F2E;


// Colors
// ------------------------------
// text
$text-color-base: #222;
$text-color-secondary: #777;
$text-color-error: #dc3545;

// links
$link-color-base: #3498db;
$link-color-hover-base: darken($link-color-base, 10%);

// borders
$border-color-base: lighten(#ced4da, 5%);

// buttons
$btn-color-primary: #00ad9e;
$btn-color-secondary: #eee;

// accents
$accent-color-primary: lighten(#45639A, 50%);
$hover-color: lighten($accent-color-primary, 3%);

// Box shadow
// ------------------------------
$box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

// Layout
// ------------------------------
$gutter: 1.25rem;
$gutter-mobile: 0.5rem;


// Font size
// ------------------------------
$font-size-base: 0.9rem;