@import 'sass/variables';

// Dialog
// ------------------------------
.dialog-header {

    border-bottom: 1px solid $border-color-base;
    margin-bottom: $gutter;
    padding-bottom: math.div($gutter, 2);

    display: flex;
    align-items: center;

    &.no-border {
        border-bottom: none;
    }

    .mat-dialog-title,
    .dialog-title {
        font-size: 1.25rem;
        margin-bottom: 0;
    }

    h5,
    h6 {
        margin-bottom: 0;
    }

    .dialog-header-title {
        font-size: 1.2rem;
        margin-bottom: 0;
    }

}

.mobile-dialog-container .mat-dialog-container{
    padding: 0px !important;
  }