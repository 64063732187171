@import './variables';

html, body {
    min-height: 100%;
    height: 100%;
}

.info-button {
    white-space: pre-line !important;
    font-size: 19px;
}

.left{float:left;}

.wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.content {

    display: flex;
    flex: 1;   

    &.container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    > aside {
        background-color: #3a404f;
        border-top: 1px solid #dde1e5;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
        z-index: 10;
    }

    > .main {
        background-color: #F5F7FA;
        flex: 1;
    }

}

.box {

    background-color:#fff;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 1px solid #f3f3f3;
    border-top: 2px solid #3A404E;
    min-height: 400px;
    padding: $gutter;

    &.box-plain {
        border-top: 1px solid #f3f3f3;
    }

}

pre {
    background-color: #f3f3f3;
    border: 1px dashed #ddd;
}

hr {

    &.dashed {
        border-top-style: dashed;
    }

}

.container {

    &.container-align-left {
        margin-left: 0;
        margin-right: 0;
    }

}

.hidden {
    display: none;
}

// Top bar
// ------------------------------
.top-bar {

    background-color: #fff;
    border-top: 1px solid $border-color-base;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    padding: 0.625rem 1.25rem;
    position: relative;
    z-index: 10;

    display: flex;
    align-items: center;

    h5, h6, .top-bar-title {
        margin-bottom: 0;
        font-size: 1.1rem;
    }

    .top-bar-back-btn {
        margin-right: math.div($gutter, 2);
    }

    > div {
        margin-right: $gutter;
    }

    .mat-icon-button {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    // need to know if navigation is present
    &.sticky {
        border-top-width: 0;
        position: fixed;
        top: -1px;
        left: 0;
        margin-left: 220px;
        width: 100%;
    }

    .actions {
        background-color: #eee;
    }

}

// Bottom bar
// ------------------------------
.bottom-bar {

    background-color: #fff;
    border-top: 1px solid $border-color-base;
    padding: 0.625rem 1.25rem;
    position: relative;
    z-index: 10;

}


// Pages
// ------------------------------
.page {

    padding: $gutter;
    width: 100%;

    .page-header {

        margin-bottom: $gutter;

        h3, h4, h5, h6, .page-title {
            line-height: 1;
            margin-bottom: 0;
            font-size: 1.25rem;
        }

    }

    .spinner-container {

        border-top: 1px solid $border-color-base;
        min-height: 300px;

        .mat-spinner {
            margin-right: 10px;
        }

    }

}

// Material tabs
// ------------------------------
.mat-tab-label {

    background-color: #f3f3f3;
    transition: background-color .25s;

    &:hover {
        background-color: #f3f3f3;
    }

    &.mat-tab-label-active {

        border-top: 2px solid #3A404E;
        background-color: #fff;
        border-left: 1px solid rgba(0,0,0,.12);
        border-right: 1px solid rgba(0,0,0,.12);
        border-radius: 4px 4px 0 0;
        box-shadow: 0 2px 2px 2px #fff;
        opacity: 1;

        .mat-tab-label-content {
            color: $text-color-base;
        }

    }

}

.mat-ink-bar {
    background-color: transparent !important;
}

.section-break {

    border-bottom: 1px dashed #ddd;
    margin: 15px 0;

    h6 {
        font-weight: 600;
    }

}


.section {

    .section-header {
        
        border-bottom: 1px dashed #ddd;

        &.no-border {
            border-bottom: none;
        }

    }

    .section-title {
        font-size: 1rem;
    }

}

// Material Stepper
// ------------------------------
.mat-horizontal-stepper-sm {

    .mat-horizontal-stepper-header {
        height: 30px;
        padding: 12px;
    }

    .mat-horizontal-content-container {
        margin-left: -2px;
        margin-right: -2px;
        padding-left: 2px;
        padding-right: 2px;
    }

    .mat-stepper-horizontal-line {
        margin-left: 0;
        margin-right: 0;
    }

}

@media (max-width: 992px) {

    .content > aside {

        height: 100%;
        position: absolute;
        z-index: 2001;

        &.closed {
            box-shadow: none;
        }

    }

}

@media (max-width: 767px) {

    .login .content > .main {
        background-color: #fff;
    }

    .page {
        padding: $gutter-mobile;
        width: 100%;
    }
    
    .box {       
        padding: $gutter-mobile;
    }
}