.primary-nav {

    min-width: 220px;
    width: 220px;
    transition: margin-left .25s;
    position: relative;

    &.closed {
        margin-left: -220px;
    }

}

@media (max-width: 767px) {

    .primary-nav {       
        width: 100%;
        transition: margin-left .25s;
        position: relative;
    
        &.closed {
            margin-left: -100%;
        }
    
    }

}
