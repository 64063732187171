// @import '~/sass/variables';

// Tables
// ------------------------------
.table {

    thead {

        background-color: #f7f7f7;

        th {
            border-bottom-width: 1px;
            font-weight: 400;
        }

    }

    $cell-padding: .75rem;
    &.table-sm {

        th, td {
            font-size: 0.85rem;
            padding: math.div($cell-padding, 2);
        }

    }

    &.table-sm-h {

        th, td {
            font-size: 0.85rem;
            padding-left: math.div($cell-padding, 2);
            padding-right: math.div($cell-padding, 2);
        }

    }

    tr {

        &.align-center td {
            vertical-align: middle;
        }

    }

    &.row-highlight {

        tr:hover {
            background-color: $hover-color;
        }

    }

}