// Badges
// ------------------------------
.badge {

    display: inline-flex;
    align-items: center;
    font-weight: 300;


    // background-color: #3498db;
    // border-radius: 10px;
    // color: #fff;
    // font-size: 10px;
    // line-height: 0.5;
    // height: 15px;
    // min-width: 15px;
    // transition: transform .25s;

    // display: flex;
    // justify-content: center;
    // align-items: center;

    // &.md {
    //     height: 18px;
    //     min-width: 18px;
    // }

    // &.lg {
    //     height: 20px;
    //     min-width: 20px;
    //     transform: translate(8px, -8px);
    // }

    &.pulse {
        transform: translate(5px, -5px) scale(1.2, 1.2);
    }

    &.badge-success {
        background-color: #00ad9e;
    }

    &.badge-info {
        background-color: #3498db;
    }

    &.badge-danger {
        background-color: #dc3545;
    }

    &.badge-warning {
        background-color: #f39c12;
        color: #fff;
    }

}

.record-status {

    &.record-status-success {
        color: #00ad9e;
    }

    &.record-status-info {
        color: #3498db;
    }

    &.record-status-danger {
        color: #dc3545;
    }

    &.record-status-warning {
        color: #f39c12;
    }

}