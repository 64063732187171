@import './variables';

body {
    color: $text-color-base;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 0.85rem;
}

button, .mat-raised-button {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

a {

    color: $link-color-base;

    &:hover {
        color: darken($link-color-base, 15%);
    }

    &.disabled {
        color: $text-color-base;
        pointer-events: none;
    }

}

strong,
.strong {
    font-weight: 600;
}

.text-no-wrap {
    white-space: nowrap;
}

.text-link {

    color: $link-color-base;

    &:hover {
        color: lighten($link-color-base, 15%);
        cursor: pointer;
    }

}

.text-info {
    color: $link-color-base !important;
}

.text-success {
    color: #00ad9e !important;
}

.text-danger {
    color: #dc3545 !important;
}

.text-warning {
    color: #f39c12 !important;
}

.text-csv > span + span::before {
    content: ", ";
}

// Icon Fonts
// ------------------------------
.icon-sm {
    font-size: 1.1rem;
}