@use 'sass:math';

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import
    '../sass/variables',
    '../sass/utils',
    '../sass/typography',
    '../sass/layout',
    '../sass/forms',
    '../sass/buttons',
    '../sass/print',
    '../sass/dialog',
    '../sass/borders',
    '../sass/side-navigation',
    '../sass/tables',
    '../sass/widgets/badges',
    '../sass/widgets/record-lists',
    '../sass/widgets/tabs',
    '../sass/widgets/toolbar',
    '../sass/widgets/material-components'
;

@media print {

    .primary-nav,
    .mat-menu-item {
        display: none !important;
    }

}
