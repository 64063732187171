// Record list
// ------------------------------
.record-list {

    .record-list-header {

        background-color: #f3f3f3;
        border-top: 1px solid $border-color-base;

        &:hover {
            background-color: #f3f3f3;
        }

    }

    .record-list-title,
    .record-title {

        font-weight: 600;

        a {
            font-weight: 600;
        }

    }

    .record {

        border-top: 1px solid $border-color-base;
        font-size: 0.85rem;
        padding: $gutter;
        transition: background-color .25s, opacity .25s;

        &:first-child {
            border-top: none;
        }

        &:hover {
            background-color: lighten($accent-color-primary, 5%);
        }

        &.no-highlight:hover {
            background-color: transparent;
        }

        .label {

            color: $text-color-secondary;
            max-width: 200px;

            &:after {
                content: ":";
            }

            &.label-placeholder::after {
                content: "";
            }

        }

        &.deprioritize {
            background-color: #eee;
            opacity: 0.5;
        }

        .value {
            padding-left: math.div($gutter, 2);
        }

        .value-commas span + span::before {
            content: ", ";
        }

        .record-actions {
            text-align: right;
        }

        .mat-button {

            i {
                font-size: 14px;
            }

        }

        .rl-item,
        .list-item {
            display: flex;
        }

        &.highlight {
            background-color: lighten(#3399cc, 45%);
            transition: background-color 1s;
        }

        // build 30 columns starting at 40 and incremententing by 5
        $column-width-start: 40;
        $column-width: $column-width-start;
        $columns: 30;
        $columns-increment: 5;

        @while $column-width < $columns * $columns-increment + $column-width-start {

            .column-#{$column-width} .label {
                min-width: #{$column-width}px;
            }

            $column-width: $column-width + $columns-increment;

        }

    }

    &.record-list-sm,
    &.record-list-small {

        .record {
            padding: math.div($gutter, 2);
        }

        .mat-paginator-container {
            min-height: 40px;
            padding-left: 0;
            padding-right: 0;
        }

        .toolbar.ssf {
            padding: 5px;
        }

    }

    .record-content {

        position: relative;

        &.loading {

            .record {
                opacity: 0;
            }

        }

    }

    .record-section-break {
        border-top: 1px dashed #ccc;
        margin-top: 10px;
        padding-top: 10px;
    }

    .no-records {
        padding-bottom: 1.25rem;
        padding-top: 1.25rem;
    }

    .toolbar {

        label {
            font-size: 12px;
            line-height: 1;
            margin-bottom: 0;
            margin-right: 5px;
            white-space: nowrap;
        }

        .form-group {

            margin-bottom: 0;

            + .form-group {
                margin-left: 5px;
            }

        }

        .form-group-datepicker {
            max-width: 260px;
        }

        .sort-icon {
            font-size: 1rem;
        }

        &.ssf {
            border-bottom: 1px solid #ddd;
            border-top: 1px solid #ddd;
            padding: 10px;
        }

        &.pagination {

            border-bottom: 1px solid #ddd;
            border-top: 1px solid #ddd;
            border-radius: 0;
            padding-bottom: 0;
            padding-top: 0;

            .mat-paginator-page-size-select {
                margin-top: 0;
            }
        }

    }

    .loading-indicator {
        align-items: flex-start !important;
        background-color: rgba(255, 255, 255, 0.85);
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        margin-top: 0 !important;
        padding-top: $gutter*2;
        z-index: 100;
    }

    .loader-record-list {
        background-color: #fff;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 100;
    }

    input[type="search"] {
        min-width: 100px;
    }

    .record-menu {

        .dropdown {

            .mat-icon-button .material-icons {
                font-size: 1.4rem;
            }
          
        }
                  
    }

}

// Record Grid
// ------------------------------
.record-list-grid {

    margin-bottom: 0;

    th {
        border-top: none;
        font-weight: 600;
    }

    th, td {
        font-size: 0.9rem;
        vertical-align: top;
    }

    tbody tr:hover td {
        background-color: lighten($accent-color-primary, 3%);
    }

    .record-action {

        align-items: center;
        background-color: transparent;
        border: none;
        color: $link-color-base;
        display: flex;
        margin: 0;
        padding: 0;

        + .record-action {
            margin-left: $gutter*2;
        }

        &:hover {
            color: darken($link-color-base, 30%);
            cursor: pointer;
        }

        i {
            font-size: 1rem;
        }

    }

}
