@import 'sass/variables';

$tab-padding-top: 10px;
$tab-background-color: #f3f3f3;

.tabs {

    border-bottom: 1px solid #ddd;
    display: flex;
    // margin-top: $gutter;
    position: relative;

    > a,
    .mat-button {

        background-color: $tab-background-color;
        display: block;
        border-radius: 4px 4px 0 0;
        color: #555;
        border: 1px solid $tab-background-color;
        border-bottom-color: #ddd;
        font-weight: 400;
        position: relative;
        margin-bottom: -1px;
        margin-right: 1px;
        transition: min-width .2s;

        + a {
            margin-left: 1px;
        }

        &:hover {
            background-color: darken($tab-background-color, 5%);
        }

        &:disabled {

            &:hover {
                cursor: not-allowed;
            }

        }

        &.active {

            background-color: #fff !important;
            border-top: 2px solid #3A404E;
            border-left-color: #ddd;
            border-right-color: #ddd;
            border-bottom-color: #fff;
            color: #222;
            outline: none;

            &:hover  {

                background-color: #fff;

                .mat-button-focus-overlay {
                    background-color: #fff;
                    opacity: 0 !important;
                }

            }

        }

    }

    .active {

        .badge {
            background-color: #3399cc;
            min-width: 14px;
        }

    }

    .badge {

        background-color: #999;
        border-radius: 10px;
        color: #fff;
        font-weight: 600;
        margin-left: 5px;

        display: flex;
        align-items: center;
        height: 14px;
        justify-content: center;

        &:empty {
            display: flex;
        }

    }

}

.tabs-floating {

    position: relative;

    .tabs {

        border-bottom: none;

        .mat-button {

            background-color: #ddd;
            box-shadow: $box-shadow;
            position: relative;
            z-index: 1;

            &.active {
                border-left-color: #fff;
                border-right-color: #fff;
            }

        }

    }

    .tab-panes {
        background-color: #fff;
        box-shadow: $box-shadow;
        border-radius: 0 4px 4px 4px;
        padding: $gutter;
        position: relative;
        z-index: 1;
    }

}

@media (min-width: 992px) {

    .tabs {

        button, a {

            &.mat-button {
                // min-width: 160px;
            }
        }

    }

}

@media (max-width: 991px) {

    .tabs {

        button, a {

            &.mat-button {
                padding-left: 8px;
                padding-right: 8px;
            }

        }

    }

}