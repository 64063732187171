@import 'sass/variables';

$fieldErrorColor: #dc3545;

.label,
label {

    .required:after,
    &.required:after {
        color: $text-color-error;
        content: "*";
        font-size: 10px;
        vertical-align: top;
    }

}

option:disabled {
    color: #999;
}

::placeholder {
    color: #999 !important;
    font-weight: 200;
}

// Form actions
// ------------------------------
form {

    max-width: 800px;

    label {
        font-size: 0.85rem;
        display: flex;
    }

    &.form-fluid {
        max-width: 100%;
    }

    $pad-v: .375rem;
    $pad-h: .75rem;

    &.form-min-height {
        min-height: 300px;
    }

    .readonly-group {

        .label {
            margin-bottom: 15px;
        }

        .value {
            margin-top: 15px;
        }

    }

    .mat-radio-group {

        &.mat-radio-group-sm {

            .mat-radio-button {

                $radioSize: 16px;

                .mat-radio-container {
                    height: $radioSize;
                    width: $radioSize;
                }

                .mat-radio-outer-circle {
                    height: $radioSize;
                    width: $radioSize;
                }

                .mat-radio-inner-circle {
                    height: $radioSize;
                    width: $radioSize;
                }

                .mat-radio-button .mat-radio-ripple {
                    height: $radioSize * 2;
                    width: $radioSize * 2;
                    left: calc(50% - 16px);
                    top: calc(50% - 16px);
                }

            }

        }

    }

    .form-group {

        &.form-group-postal-code {

            input.postal-code {
                width: 55%;
            }

            input.postal-geo {
                width: 40%;
            }

        }

        &.form-group-inline {
            
            margin-bottom: 0;

            label {
                margin-bottom: 0;
                line-height: 22px;
            }
        
        }

    }

    .form-control {

        font-size: 0.85rem;
        height: calc(2rem + 2px);
        line-height: 1;
        padding: math.div($pad-v, 2) $pad-h;

        &.is-invalid {
            background: none;
        }

        &:disabled {
            background-color: #e9ecef;
        }

    }

    .field-invalid {

        border-color: $fieldErrorColor;
        
        .form-control {
            border-color: $fieldErrorColor;
        }

    }

    .form-errors {
        color: $fieldErrorColor;
    }

    .field-errors {

        margin-top: 10px;

        > div {

            color: $fieldErrorColor;
            font-size: 0.75rem;

            &::first-letter {
                text-transform: capitalize;
            }

        }

    }

    .field-error {
        color: $fieldErrorColor;
        font-size: 0.75rem;
    }

    .invalid-feedback {
        font-size: .75rem;
    }

    .input-group-text {
        font-size: 0.85rem;
        padding-bottom: math.div($pad-v, 2);
        padding-top: math.div($pad-v, 2);
    }

    textarea {

        &.form-control {
            line-height: 1.5;
            padding: $pad-v $pad-h;
        }

        &.textarea-base-height {
            height: 80px;
        }
        
        &.textarea-lg-height {
            height: 160px;
        }

    }

    .form-actions {

        button + button {
            margin-left: $gutter;
        }

    }

    .form-group.is-invalid .invalid-feedback {
        display: block !important;
    }

    .form-section {

        header {

            border-bottom: 1px dashed #ddd;
            margin: 15px 0;

            h6 {
                font-weight: 600;
            }

        }

    }

    .form-control-value {
        font-size: 0.9rem;
        margin-top: 10px
    }

    .form-control-value-no-label {
        font-size: 0.9rem;
        margin-top: 30px
    }

    .label-char-count {

        display: flex;
        align-items: flex-end;

        .char-count {
            font-size: 0.75rem;
            margin-left: auto;
        }

    }

    textarea.min-height {
        height: 80px;
    }

    .autocomplete {

        position: relative;

        .autocomplete-options {

            border: 1px solid #ddd;
            border-radius: 4px;
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
            list-style: none;
            margin: 0;
            padding: 0;
            position: absolute;
            width: 100%;
            z-index: 100;

            &.hidden {
                display: none;
            }

            li {

                background-color: #fff;
                padding: 5px;

                &:first-child {
                    border-radius: 4px 4px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 4px 4px;
                }

                &:hover {
                    background-color: lighten($accent-color-primary, 3%);
                    cursor: pointer;
                }

            }

        }

    }

    .form-control-sm {

        + .mat-datepicker-toggle button {

            height: 30px;
            width: 30px;

            svg {
                height: 20px;
                width: 20px;
            }

        }

    }

    input[type="checkbox"],
    input[type="radio"] {

        &:disabled + label {
            opacity: 0.5;
        }

    }

    .read-only {

        $readOnlyBgColor: #f9f9f9;

        textarea:disabled,
        input[type="text"]:disabled,
        input[type="number"]:disabled,
        input[type="search"]:disabled,
        input[type="email"]:disabled,
        input.form-control:disabled
        {
            background-color: $readOnlyBgColor;
        }

        select:disabled {
            background-color: $readOnlyBgColor;
            -moz-appearance: none;
            -webkit-appearance: none;
        }

        input[type="checkbox"],
        input[type="radio"] {

            &:disabled {

                opacity: 1;

                + label {
                    opacity: 1;
                }

            }

        }

    }

}

.form-group-inline {

    display: flex;

    label {
        margin: 5px 10px 0 0;
        white-space: nowrap;
    }


}

// Checkboxes
// ------------------------------
.form-check {

    .form-check-input {
        margin-top: 0.2rem;
    }

}

// override disabled and checked checkbox style
.disabled-checkbox {

    &.mat-checkbox-checked {

        &.mat-checkbox-disabled {

            .mat-checkbox-background {
                background-color: #b0b0b0 !important;
            }
        }
    }
}

// material checkbox
.mat-checkbox {

    border-width: 1px;

    &.mat-checkbox-checked {

        .mat-checkbox-background {
            background-color: $btn-color-primary;
        }

    }

    &.align-items-top {

        .mat-checkbox-inner-container {
            margin-top: 4px;
        }

        .mat-checkbox-label {
            white-space: normal;
        }

    }

    &.mat-checkbox-disabled {

        // opacity: 0.5;

        &:hover {
            cursor: not-allowed;
        }

    }

    &.mb-0 {

        label {
            margin-bottom: 0;
        }

    }

}

// form section
.form-section {

    margin-bottom: $gutter;
    
    &.no-border .form-section-header {
        border-bottom: none;
    }
    
    .form-section-header {
        
        border-bottom: 1px dashed #ddd;
        margin-bottom: $gutter;

        .form-section-title {
            font-size: 1rem;
            font-weight: 600;
        }

        .form-section-description {
            color: #777;
            margin-bottom: math.div($gutter, 2);
        }

    }

}

// Material Autocomplete
.mat-autocomplete-panel {

    .mat-option {
        height: 24px;
        line-height: 24px;
    }

    .mat-option-text {
        font-size: 0.85rem;
    }

}

// ranges
.field-range {

    .range {

        display: flex;
        align-items: flex-start;

        .form-group {
            width: 47.33%;
        }

        .to {
            font-size: 0.85rem;
            margin-top: 5px;
            text-align: center;
            width: 5.5%;
        }

    }

}

// Multiselect Fields
// ------------------------------
.ms-options-container {
    position: relative;
}

$duration: .25s;
.ms-options {

    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: $box-shadow;
    border-radius: 4px;
    display: none;
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
    transition: opacity $duration, max-height $duration;
    max-height: 0;
    width: 100%;

    &.open {
        display: block;
    }

    &.in {
        max-height: 300px;
        opacity: 1;
    }

}

.ms-option {

    input[type="checkbox"] {
        margin-right: .5rem;
        margin-top: 4px;
    }

    label {

        display: flex;
        margin-bottom: 0;
        padding: .5rem .5rem;
        width: 100%;

        &:hover {
            background-color: #eee;
        }

    }

    &:first-child {
        border-bottom: 1px solid #ddd;
    }

}


.highlight-value-change {
    animation-iteration-count: 1;
    animation-duration: 2s;
    animation-name: highlightValueChange;
}

// Checkbox groups
// -------------------------
.checkbox-group {

    .checkbox-group-controls {
  
        display: flex;
        flex-direction: column;
  
    }
  
}

@keyframes highlightValueChange {

    0% {
        background-color: lighten(#ffff00, 40%);
    }

    100% {
        background-color: #fff;
    }

}

@media (max-width: 767px) {

   form .form-control {

        font-size: 0.85rem;
        height: calc(2.5rem + 2px);      
    }

    .form-section .form-section-header{
        margin-bottom: 1rem;
    }

}

