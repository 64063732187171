@import 'sass/variables';

// Material Menu
// ------------------------------
.mat-menu-panel {

  min-height: 48px !important;
  max-width: 500px !important;

  &.sub-menu {
    margin-top: 1rem;
  }

  .menu-icon-btn {
    display: flex;
    align-items: center;    
  }

  .mat-menu-icon {
    font-size: 1rem;
    margin-right: 4px;
  }

}

.mat-dialog-container {
  margin-left: 20px;
  margin-right: 20px;
}


// Material Stepper Horizontal
// ------------------------------
.mat-stepper-horizontal {

  &.mat-stepper-sm {

    .mat-step-header {
      height: 48px;
      margin-left: 12px;
      margin-right: 12px;
      padding: 0 12px;
    }

  }

  &.mat-stepper-0 {

    .mat-step-header {
      height: 48px;
      margin-left: 0;
      margin-right: 0;
      padding: 0 12px;
    }

    .mat-stepper-horizontal-line {
      padding-left: 10px;
      padding-right: 10px;
    }

    .mat-horizontal-content-container {
      padding-left: 5px;
      padding-right: 5px;
    }

  }

}


// Material checkbox
// ------------------------------
.mat-checkbox {

  &.mb-0 {

    label {
      margin-bottom: 0
    }

  }

  &.mat-checkbox-btn {

    .mat-checkbox-layout {

      background-color: #f7f7f7;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px 10px;

      &:hover {
        background-color: #f3f3f3;
      }

    }

    &.mat-checkbox-checked .mat-checkbox-layout {
      background-color: lighten(#36AD9E, 50%);
    }

    
  }

  &.mat-checkbox-disabled {

    opacity: 1;

    .mat-checkbox-label {
      color: $text-color-base; 
    }

  }

}

// Material radio
// ------------------------------
.mat-radio-button {

  $radio-checked-color:#36AD9E;

  &.mat-primary {

    &.mat-radio-checked {

      .mat-radio-outer-circle {
        border-color: $radio-checked-color
      }

      .mat-radio-inner-circle {
        background-color: $radio-checked-color;
      }

    }


  }

}


// Material button group
// ------------------------------
.mat-btn-group-sm {

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 1;
    padding: 4px 8px;
  }

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard, &.mat-button-toggle-group-appearance-standard {
    border-color: #CED4DA;
  }
  
  &.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border-left-color: #CED4DA;
  }

  .mat-button-toggle-checked {
    background-color: lighten(#3498db, 35%);
  }

}

// Material badge
// ------------------------------
.mat-badge {

  &.mat-badge-secondary {

    .mat-badge-content {
      background-color: #ECEFF1;
      color: #222;
      font-weight: 600;
      z-index: 100;
    }

  }

}

// Material slide toggle
// ------------------------------
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar { 
  background-color: lighten(#36AD9E, 30%);
} 

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  $radio-checked-color:#36AD9E;
  background-color: $radio-checked-color;
}

// Material autocomplete -  mouse hover and arrow key active color
// ------------------------------
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled),.mat-option.mat-active,
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: lighten(#3498db, 40%); 
}

.customize-mat-icon-button{height:29px !important;}

.mat-tooltip {
  font-size: 0.85rem !important;
}

