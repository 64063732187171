// Toolbar
// ------------------------------
.toolbar {

    border-top: 1px solid $border-color-base;
    background-color: #f3f3f3;
    padding: 5px;

    .btn-secondary {

      background-color: lighten(#f3f3f3, 3%);
      border-color: #ced4da;

      &:hover {
        background-color: darken(#f3f3f3, 10%);
      }

    }

    .search-icon {
      color: $text-color-base;
      font-size: 18px;
      display: flex;
      align-content: center;
    }

    // pagination
    .mat-paginator {
        background-color: transparent;
    }

}